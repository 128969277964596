import React from "react";

import SEO from "../components/seo";

import { Container, Section } from "../components/anti/grid/grid";
import { Loader } from "../components/anti/loader/loader";

// Images
import imgLogo from "../assets/img/wedding/logo-invite-black.png";
import imgEmail from "../assets/img/wedding/ic-email.png";
import imgIg from "../assets/img/wedding/ic-ig.png";
import graphicLeft from "../assets/img/wedding/grfx-line-left.png";
import graphicRight from "../assets/img/wedding/grfx-line-right.png";

const HomePage = () => {
  return (
    <>
      <Loader />
      <SEO title="Home" />

      <Section className="login-page">
        <Container>
          <div className="home-wrapper text-center">
            <img
              src={graphicLeft}
              className="graphic-img left"
              alt="graphic left"
            />
            <img
              src={graphicRight}
              className="graphic-img right"
              alt="graphic right"
            />
            <div className="p-box">
              <h2 className="h5 mb-3">Welcome to</h2>
              <img
                src={imgLogo}
                className="img-fluid logo"
                alt="logo wedding"
              />
              <p className="subtitle mb-5">
                Duis imperdiet dolor elit, et rutrum arcu aliquet ac. Fusce
                lobortis mollis efficitur. Duis nec commodo metus, non ultrices
                tellus. Mauris a scelerisque tortor. Morbi vel scelerisque
                felis, at consequat sem.
              </p>
              <h2 className="h5 reach-us">Reach Us At</h2>
              <ul>
                <li>
                  <a href="mailto:info@weddinggm.com" target="_blank">
                    <img src={imgEmail} alt="icon email" />
                    info@weddinggm.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/@weddinggm"
                    target="_blank"
                  >
                    <img src={imgIg} alt="icon instagram" />
                    @weddinggm
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default HomePage;
